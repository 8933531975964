<template>
  <div class="row">
    <div class="col-12 col-lg-4">
      <div class="card">
        <div class="card-header">البيانات الشخصية</div>
        <div class="card-body">
          <div class="media">
            <a class="d-flex" href="#">
              <img src="../../assets/images/arab-avatar.png" alt="" />
            </a>
            <div class="media-body">
              <br /><br />
              <h4>{{ teacher.name }}</h4>
              # {{ teacher.number }}
            </div>
          </div>
          <br />
          <table class="table">
            <tbody>
              <tr>
                <td>عدد دقائق التأخر الكلية</td>
                <td>{{ teacher.late_minutes.toFixed(1) }} دقيقة</td>
              </tr>
              <tr>
                <td>عدد ساعات التأخر الكلية</td>
                <td>{{ (teacher.late_minutes / 60).toFixed(1) }} ساعة</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <p>مساءلات المعلم: <button class="btn btn-sm btn-primary" v-b-modal.modal-2>+ اضافة مساءلة</button></p>
      <div v-if="teacher.asks">
        <div v-if="teacher.asks.length">
          <div class="row">
          <template v-for="ask in teacher.asks">
              <div class="col-12"
                v-bind:key="ask._id"
                v-if="ask.type != 'ghiab' && ask.type != 'late' && ask.type != 'lateBetween'"
              >
                <div class="card card-body">
                  <h4
                    @click="
                      go(
                        '/aks/' +
                          teacher._id + '/' + ask.date + '/' + ask.type
                      )
                    "
                  >
                    <span v-if="ask.type == '1'">
                      عدم تواجد داخل الفصل
                    </span>
                    <span v-if="ask.type == '2'">
                      خروجك من الحصة قبل انتهاء الوقت
                    </span>
                    <span v-if="ask.type == '3'">
                      عدم تحضير الدروس
                    </span>
                    <span v-if="ask.type == '4'">
                      عدم المناوبة في الفسحة
                    </span>
                    <span v-if="ask.type == '5'">
                      عدم المناوبة في الممرات
                    </span>
                    <span v-if="ask.type == '6'">
                      عدم المناوبة في نهاية الدوام
                    </span>
                    <span v-if="ask.type == '7'">
                      عدم الحضور لحصص الانتظار
                    </span>
                  </h4>
                  <h5>
                    بتاريخ
                    <span dir="ltr">{{ ask.date.replaceAll("-", "/") }}</span>
                  </h5>
                </div>
              </div>
            </template>
          </div>
          </div>
          </div>
    </div>
    <div class="col-12 col-lg-8">
      <div class="card">
        <div class="card-header">
          📃 تقارير الحضور وغياب وتأخر (آخر 30 يوم)
          <button
            class="btn btn-primary"
            @click="sstatus('all')"
            v-b-modal.modal-1
          >
            عرض التفاصيل
          </button>
          <div>
            من
            <input
              type="date"
              ref="from"
              v-model="from"
              @change="getReports()"
            />
            إلى
            <input type="date" ref="to" v-model="to" @change="getReports()" />
          </div>
        </div>
      </div>
      <div class="row" v-if="reports.date">
        <div class="col-12 col-lg-4" @click="sstatus(1)" v-b-modal.modal-1>
          <statistic-card-horizontal
            icon="CheckSquareIcon"
            :statistic="reports.attends.length"
            statistic-title="عدد أيام الحضور"
          />
        </div>
        <div class="col-12 col-lg-4" @click="sstatus(2)">
          <statistic-card-horizontal
            icon="ClockIcon"
            v-b-modal.modal-1
            :statistic="reports.lates.length"
            :statistic-title="`عدد أيام التأخر (${(
              reports.late_time / 60
            ).toFixed(1)} ساعة)`"
          />
        </div>
        <div class="col-12 col-lg-4" @click="sstatus(3)">
          <statistic-card-horizontal
            icon="XCircleIcon"
            v-b-modal.modal-1
            :statistic="reports.ghiab.length"
            statistic-title="عدد أيام الغياب"
          />
        </div>
      </div>
      <div v-if="teacher.asks">
        <div v-if="teacher.asks.length">
          <div class="row">
            <div class="col-12">
              <div class="alert">مساءلات الغياب والتأخر:</div>
            </div>
            <template 
              v-for="ask in teacher.asks">
            <div
              class="col-12"
              v-bind:key="ask._id"
              v-if="ask.type == 'ghiab' || ask.type == 'late' || ask.type == 'lateBetween'"
            >
              <div class="card card-body">
                <h4
                  v-if="ask.type == 'ghiab'"
                  @click="
                    go(
                      '/reports/ask-ghiab/' +
                        ask.date +
                        '/' +
                        teacher.number +
                        '/' +
                        teacher._id
                    )
                  "
                >
                  مساءلة غياب
                </h4>
                <h4
                  v-if="ask.type == 'late'"
                  @click="
                    go(
                      '/reports/ask-late/' +
                        ask.date +
                        '/' +
                        teacher.number +
                        '/' +
                        teacher._id +
                        '/' +
                        ask.mins +
                        '/' +
                        ask.date +
                        ' ' +
                        ask.time
                    )
                  "
                >
                  مساءلة تأخر
                </h4>
                <h4
                  v-if="ask.type == 'lateBetween'"
                  @click="
                    go(
                      '/reports/ask-late-between/' +
                        ask.from +
                        '/' +
                        ask.to +
                        '/' +
                        ask.mins +
                        '/' +
                        teacher._id +
                        '/' +
                        ask.date
                    )
                  "
                >
                  مساءلة تأخر لفترة
                </h4>
                <h5 v-if="ask.type != 'lateBetween'">
                  بتاريخ
                  <span dir="ltr">{{ ask.date.replaceAll("-", "/") }}</span>
                </h5>
                <h5 v-if="ask.type == 'lateBetween'">
                  من
                  <span dir="ltr">{{ ask.from.replaceAll("-", "/") }}</span> إلى
                  <span dir="ltr">{{ ask.to.replaceAll("-", "/") }}</span>
                </h5>
              </div>
            </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <!-- basic modal -->
    <b-modal
      id="modal-1"
      title="تفاصيل التقرير"
      ok-only
      ok-title="حسناً، شكراً لك"
    >
      <b-card-text>
        <div class="col-12 g text-center">
          <button class="btn btn-info" @click="print()">طباعة الجدول</button>
        </div>
        <div class="col-12 table-responsive" ref="gtable">
          <table class="table table-bordered table-hover">
            <thead>
              <th>التاريخ</th>
              <th>الحالة</th>
            </thead>
            <tbody>
              <tr v-for="a in reports.arr" v-bind:key="a._id">
                <template v-if="a.status == status || status == 'all'">
                  <td>
                    <strong>{{ a.date }}</strong>
                    <br />
                    {{ a.hdate.year + "/" + a.hdate.month + "/" + a.hdate.day }}
                  </td>
                  <td>
                    <span class="badge badge-secondary" v-if="a.status == 0"
                      >خارج وقت الدوام</span
                    >
                    <span class="badge badge-success" v-if="a.status == 1"
                      >حاضر</span
                    >
                    <span class="badge badge-warning" v-if="a.status == 2"
                      >متأخر</span
                    >
                    <div v-if="a.status == 2">
                      متأخر: {{ a.late_time }} دقيقة ({{
                        (a.late_time / 60).toFixed(1)
                      }}
                      ساعة)
                    </div>
                    <span class="badge badge-danger" v-if="a.status == 3"
                      >غائب</span
                    >
                    <span class="badge badge-primary" v-if="a.status == 5"
                      >يوم إجازة (لم يتم الفحص فيه)</span
                    >
                  </td>
                </template>
              </tr>
            </tbody>
          </table>
        </div>
      </b-card-text>
    </b-modal>
    <!-- basic modal -->
    <b-modal
      id="modal-2"
      ok-only
    >
      <div class="row">
        <div class="col-12 g">
          <button class="btn btn-primary btn-block" @click="addAsk(1)">
             عدم تواجد داخل الفصل
          </button>
        </div>
        <div class="col-12 g">
          <button class="btn btn-primary btn-block" @click="addAsk(2)">
            خروجك من الحصة قبل انتهاء الوقت
          </button>
        </div>
        <div class="col-12 g">
          <button class="btn btn-primary btn-block" @click="addAsk(3)">
             عدم تحضير الدروس
          </button>
        </div>
        <div class="col-12 g">
          <button class="btn btn-primary btn-block" @click="addAsk(4)">
            عدم المناوبة في الفسحة
          </button>
        </div>
        <div class="col-12 g">
          <button class="btn btn-primary btn-block" @click="addAsk(5)">
            عدم المناوبة في الممرات
          </button>
        </div>
        <div class="col-12 g">
          <button class="btn btn-primary btn-block" @click="addAsk(6)">
            عدم المناوبة في نهاية الدوام
          </button>
        </div>
        <div class="col-12 g">
          <button class="btn btn-primary btn-block" @click="addAsk(7)">
            عدم الحضور لحصص الانتظار
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  BCard,
  BCardText,
  BLink,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
const axios = require("axios");
var moment = require("moment-hijri");
export default {
  components: {
    BCard,
    BCardText,
    BDropdown,
    BDropdownItem,
    StatisticCardHorizontal,
    BLink,
  },
  data() {
    return {
      teacher: {},
      id: null,
      status: "all",
      reports: [],
      info: {},
      from: new Date(new Date().setDate(new Date().getDate() - 30))
        .toISOString()
        .split("T")[0],
      to: new Date().toISOString().split("T")[0],
    };
  },
  created() {
    this.id = window.location.pathname.split("/overview/")[1];
    var _g = this;
    axios
      .post(localStorage.getItem("api") + "/teachers/info", {
        jwt: localStorage.getItem("jwt"),
      })
      .then(function (r) {
        if (r.data.status == 200) {
          _g.$toast({
            component: ToastificationContent,
            props: {
              title: "حدث خطأ JWT",
              icon: "WarningIcon",
              variant: "danger",
            },
          });
        } else {
          _g.info = r.data.response;
        }
      })
      .catch(function () {
        _g.$toast({
          component: ToastificationContent,
          props: {
            title: "حدث خطأ",
            icon: "WarningIcon",
            variant: "danger",
          },
        });
      });
    axios
      .post(localStorage.getItem("api") + "/teachers/teacher", {
        jwt: localStorage.getItem("jwt"),
        id: this.id,
      })
      .then(function (r) {
        if (r.data.status == 200) {
          _g.$toast({
            component: ToastificationContent,
            props: {
              title: "حدث خطأ JWT",
              icon: "WarningIcon",
              variant: "danger",
            },
          });
        } else {
          _g.teacher = r.data.response;

          setTimeout(() => {
            _g.getReports();
          }, 1);
        }
      })
      .catch(function () {
        _g.$toast({
          component: ToastificationContent,
          props: {
            title: "حدث خطأ",
            icon: "WarningIcon",
            variant: "danger",
          },
        });
      });
  },
  methods: {
    print() {
      var printWindow = window.open("", "");
      printWindow.document.write(
        "<html><head dir='rtl'><title>tahdir.net</title>"
      );

      //Print the Table CSS.
      printWindow.document.write('<style type = "text/css">');
      printWindow.document.write(`
         body
          {
              font-family: Arial;
              font-size: 10pt;
              direction: rtl;
          }
          table
          {
              border: 1px solid #ccc;
              border-collapse: collapse;
              width: 100%
          }
          table th
          {
              background-color: #F7F7F7;
              color: #333;
              font-weight: bold;
          }
          table th, table td
          {
              padding: 5px;
              border: 1px solid #ccc;
          }
          .no {
            display: none;
          }
        `);
      printWindow.document.write("</style>");
      printWindow.document.write("</head>");

      //Print the DIV contents i.e. the HTML Table.
      moment.locale("ar-SA");
      var days = [
        "اﻷحد",
        "اﻷثنين",
        "الثلاثاء",
        "اﻷربعاء",
        "الخميس",
        "الجمعة",
        "السبت",
      ];
      var d = new Date(this.$refs.from.value);
      var start_dayname = days[d.getDay()];
      var start_date =
        moment(this.$refs.from.value, "YYYY-M-D").format("iYYYY/iM/iD") + "هـ";
      d = new Date(this.$refs.to.value);
      var to_dayname = days[d.getDay()];
      var to_date =
        moment(this.$refs.to.value, "YYYY-M-D").format("iYYYY/iM/iD") + "هـ";
      //Print the DIV contents i.e. the HTML Table.
      printWindow.document.write(`<body>
      <div style='width: 100%; padding: 20px;'>
        <div style='width: 30%; text-align: center; display: inline-block'>
          <h3>
            المملكة العربية السعودية
            <br><br>
            وزارة التعليم
            <br><br>
            ${this.info.school_title}
          </h5>
        </div>
        <div style='width: 30%; text-align: center; display: inline-block'>
          <img src='https://cdn-static.brmja.com/storage/scrapped/62793ceb03c19132886968.jpg' style='width: 50%'>
          <br>
          <h2>تقرير حضور وغياب وتأخر المعلم <u>${this.teacher.name}</u> لفترة</h2>
        </div>
        <div style='width: 30%; text-align: center; display: inline-block'>
          <h3>
          بداية من: ${start_dayname} الموافق ${start_date}
          <br><br>
          إلى: ${to_dayname} الموافق ${to_date}
          </h3>
        </div>
      </div>
      `);
      var divContents = this.$refs.gtable.innerHTML;
      printWindow.document.write(divContents);
      printWindow.document.write(
        "<br><center><strong>تم استخراج التقرير بواسطة التحضير الذكي www.tahdir.net</strong></center></body>"
      );

      printWindow.document.write("</html>");
      printWindow.document.close();
      setTimeout(() => {
        printWindow.print();
      }, 1000);
    },
    sstatus(t) {
      this.status = t;
    },
    getReports() {
      var g = this,
        _g = this;
      setTimeout(() => {
        axios
          .post(localStorage.getItem("api") + "/reports/between", {
            jwt: localStorage.getItem("jwt"),
            from: this.$refs.from.value,
            to: this.$refs.to.value,
            single: this.id,
          })
          .then(function (r) {
            if (r.data.status == 100) {
              g.loading = false;
              g.reports = r.data.response[0];
            } else {
              g.$toast({
                component: ToastificationContent,
                props: {
                  title: "حدث خطأ 2",
                  icon: "TimesIcon",
                  variant: "danger",
                },
              });
            }
          })
          .catch(function () {
            g.loading = false;
            g.table = false;
            g.$toast({
              component: ToastificationContent,
              props: {
                title: "حدث خطأ",
                icon: "TimesIcon",
                variant: "danger",
              },
            });
          });
      }, 1);
    },
    addAsk(type){
      var g = this;
      this.$router.push('/aks/' + this.teacher._id + '/' + new Date().toISOString().split('T')[0] + '/' + type)
    },
    go(url) {
      this.$router.push(url);
    },
  },
};
</script>
<style scoped>
h4 {
  color: blue !important;
}
h4:hover {
  color: darkred !important;
  cursor: pointer;
}
</style>